@charset "UTF-8";
/*
OZ RESET CSS
Author: ONZE
Author URI: http://on-ze.com
Version: 1.1
License: GNU General Public License
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/
* {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  font: inherit;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  text-decoration: none;
  vertical-align: baseline; }

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%; }

body {
  font-size: 10px;
  font-size: 1.0rem;
  line-height: 1;
  position: relative;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%; }

article, aside, dialog, figure, footer, header, main, menu, nav, section {
  display: block; }

audio, canvas, video {
  display: inline-block; }

hr {
  display: block; }

ol,
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

input,
select {
  vertical-align: middle; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show; }

img {
  vertical-align: bottom; }

html {
  font-size: 62.5%; }

@media only screen and (min-width: 737px) and (max-width: 1024px) {
  html {
    zoom: 0.72;
    font-size: 50%; } }

body {
  background-image: url(/common/img/common/bg_body02.png), url(/common/img/common/bg_body01.png);
  background-attachment: fixed, scroll;
  background-size: cover, auto;
  background-repeat: no-repeat, repeat;
  background-position: center bottom, left top;
  color: #222;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 500;
  line-height: 1.8; }

#wrap {
  width: 1000px;
  margin: 0 auto;
  font-size: 1.4rem; }

.sp_view {
  display: none !important; }

strong {
  color: #ff3737; }

em {
  font-weight: bold; }

@font-face {
  font-family: 'Maru Folk Regular';
  font-style: normal; }

@font-face {
  font-family: 'Maru Folk Medium';
  font-style: normal; }

/* ============================================================ */
/* リンク設定 */
/* ============================================================ */
a {
  color: #000;
  text-decoration: none;
  transition: all 0.3s; }
  a:hover {
    text-decoration: none; }
    a:hover img {
      opacity: 0.8; }
  a img {
    transition: all 0.3s; }

/* ============================================================ */
/* clearfix */
/* ============================================================ */
.clearfix::after {
  display: block;
  content: "";
  clear: both; }

/* ============================================================ */
/* align */
/* ============================================================ */
.al_left {
  text-align: left !important; }

.al_center {
  text-align: center !important; }

.al_right {
  text-align: right !important; }

/* ============================================================ */
/* 折り返し処理 */
/* ============================================================ */
.nowrap {
  white-space: nowrap !important; }

.wrap {
  white-space: normal !important; }

/* ============================================================ */
/* icon font */
/* ============================================================ */
@font-face {
  font-family: 'icomoon';
  src: url("/common/icon/fonts/icomoon.eot?wa978a");
  src: url("/common/icon/fonts/icomoon.eot?wa978a#iefix") format("embedded-opentype"), url("/common/icon/fonts/icomoon.woff2?wa978a") format("woff2"), url("/common/icon/fonts/icomoon.ttf?wa978a") format("truetype"), url("/common/icon/fonts/icomoon.woff?wa978a") format("woff"), url("/common/icon/fonts/icomoon.svg?wa978a#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-lock:before {
  content: "\f023"; }

.icon-chevron-left:before {
  content: "\f053"; }

.icon-chevron-right:before {
  content: "\f054"; }

.icon-chevron-up:before {
  content: "\f077"; }

.icon-chevron-down:before {
  content: "\f078"; }

.icon-bars:before {
  content: "\f0c9"; }

.icon-navicon:before {
  content: "\f0c9"; }

.icon-reorder:before {
  content: "\f0c9"; }

.icon-sitemap:before {
  content: "\f0e8"; }

.icon-angle-left:before {
  content: "\f104"; }

.icon-angle-right:before {
  content: "\f105"; }

.icon-angle-up:before {
  content: "\f106"; }

.icon-angle-down:before {
  content: "\f107"; }

.icon-window:before {
  content: "\e900"; }

.icon-info:before {
  content: "\e903"; }

.icon-tel:before {
  content: "\e904"; }

.icon-recruit:before {
  content: "\e905"; }

.icon-mail:before {
  content: "\e906"; }

.icon-welfare:before {
  content: "\e907"; }

.icon-voice:before {
  content: "\e908"; }

.icon-education:before {
  content: "\e909"; }

.icon-facility:before {
  content: "\e90a"; }

.icon-nurse:before {
  content: "\e90b"; }

.icon-home:before {
  content: "\e90c"; }

.fit-sidebar-fixed {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: fixed; }

.fit-sidebar-blank {
  height: auto !important;
  z-index: 0;
  background-color: transparent;
  visibility: hidden;
  display: inline; }

header {
  float: left;
  width: 240px !important;
  padding: 0 0 11px;
  text-align: center;
  background: #fff;
  border: 1px solid #e7e7e7;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0 0 5px 5px;
  border-top: none;
  position: fixed;
  top: 0; }
  header .logo {
    padding: 30px 0 25px; }
  header address span {
    display: block;
    font-size: 1.2rem;
    line-height: 1.2; }
    header address span:first-child {
      margin: 0 0 5px;
      color: #39a8cc;
      font-family: 'Maru Folk Medium', sans-serif;
      font-size: 2.0rem; }
  @media all and (-ms-high-contrast: none) {
    header address *::-ms-backdrop, header address span:first-child {
      margin: 0 0 2px; } }

#gnavi ul {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px dotted #dfdddd;
  margin: 0 0 10px;
  color: #ff7996; }
  #gnavi ul li {
    width: 50%;
    border-bottom: 1px dotted #dfdddd;
    box-sizing: border-box; }
    #gnavi ul li:nth-child(even) {
      border-left: 1px dotted #dfdddd; }
    #gnavi ul li:nth-child(7) {
      width: 100%;
      border-left: none; }
      #gnavi ul li:nth-child(7) a {
        height: 60px;
        padding: 5px 0 0 65px;
        text-align: left; }
        #gnavi ul li:nth-child(7) a i {
          right: auto;
          left: 12px;
          bottom: 10px; }
    #gnavi ul li:nth-child(8) {
      width: 100%; }
      #gnavi ul li:nth-child(8) a {
        height: 60px;
        padding: 18px 0 0 65px;
        text-align: left; }
        #gnavi ul li:nth-child(8) a i {
          right: auto;
          left: 15px; }
    #gnavi ul li a {
      position: relative;
      display: block;
      height: 75px;
      padding: 45px 0 0;
      color: #ff7996;
      font-family: 'Maru Folk Regular', sans-serif;
      font-size: 1.4rem;
      text-align: center; }
      #gnavi ul li a i {
        position: absolute;
        bottom: 28px;
        left: 0;
        right: 0;
        color: #ff7996;
        font-size: 4.4rem; }
      #gnavi ul li a span {
        color: #222; }
      #gnavi ul li a:hover {
        background: #ff7996; }
        #gnavi ul li a:hover i,
        #gnavi ul li a:hover span {
          color: #fff; }
    #gnavi ul li#gnav_inquiry i {
      bottom: 13px;
      font-size: 3.7rem; }

#index #gnav_home a,
.about #gnav_about a,
.facility #gnav_facility a,
.education #gnav_education a,
.voice #gnav_voice a,
.welfare #gnav_welfare a,
.inquiry #gnav_inquiry a,
.recruit #gnav_recruit a {
  background: #ff7996;
  color: #fff; }
  #index #gnav_home a i,
  #index #gnav_home a span,
  .about #gnav_about a i,
  .about #gnav_about a span,
  .facility #gnav_facility a i,
  .facility #gnav_facility a span,
  .education #gnav_education a i,
  .education #gnav_education a span,
  .voice #gnav_voice a i,
  .voice #gnav_voice a span,
  .welfare #gnav_welfare a i,
  .welfare #gnav_welfare a span,
  .inquiry #gnav_inquiry a i,
  .inquiry #gnav_inquiry a span,
  .recruit #gnav_recruit a i,
  .recruit #gnav_recruit a span {
    color: #fff; }

#contents {
  width: 740px;
  min-height: 600px;
  margin: 0 0 0 260px;
  padding: 50px 0 0; }

/*----- #topic_path -----*/
#topic_path {
  margin: 15px 0;
  color: #5c5c5c;
  font-size: 1.1rem; }
  #topic_path li {
    display: inline-block; }
    #topic_path li:not(:last-child)::after {
      margin: 0 2px;
      content: "＞"; }
  #topic_path .icon-home {
    position: relative;
    top: 4px;
    color: #919191;
    font-size: 2rem;
    line-height: 0.5; }

/*----- .midashi -----*/
.midashi01 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 215px;
  background-position: 0 0;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  color: #ff7996;
  font-family: 'Maru Folk Medium';
  font-size: 3.4rem;
  text-shadow: 0 0 5px white, 1px 1px 12px white, -1px -1px 12px white, 1px -1px 12px white, -1px 1px 12px white;
  line-height: 1.2; }
  .midashi01 i {
    font-size: 10.0rem;
    text-align: center;
    line-height: 0.9; }
  .midashi01 span {
    padding: 0 0 15px; }
  .midashi01::before, .midashi01::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-radius: 5px; }
  .midashi01::before {
    border: 3px solid #fff; }
  .midashi01::after {
    border: 1px solid #e7e7e7; }

.about .midashi01 {
  background-image: url(/common/img/about/img_visual.png); }

.facility .midashi01 {
  background-image: url(/common/img/facility/img_visual.png); }

.education .midashi01 {
  background-image: url(/common/img/education/img_visual.png); }

.voice .midashi01 {
  background-image: url(/common/img/voice/img_visual.png); }

.welfare .midashi01 {
  background-image: url(/common/img/welfare/img_visual.png); }

.inquiry .midashi01 {
  background-image: url(/common/img/inquiry/img_visual.png); }

.recruit .midashi01 {
  background-image: url(/common/img/recruit/img_visual.png); }

.privacy .midashi01 {
  background-image: url(/common/img/privacy/img_visual.png); }

.sitemap .midashi01 {
  background-image: url(/common/img/sitemap/img_visual.png); }

.news .midashi01 {
  background-image: url(/common/img/news/img_visual.png); }

.midashi02 {
  position: relative;
  height: 60px;
  margin: 0 0 25px -24px;
  padding: 0 0 0 46px;
  background: #ff7996;
  border-radius: 0 30px 30px 0;
  color: #fff;
  font-size: 2.8rem;
  line-height: 60px; }
  .midashi02::before {
    position: absolute;
    top: 50%;
    left: 24px;
    display: block;
    content: "";
    width: 12px;
    height: 12px;
    margin: -6px 0 0;
    border: 3px solid #fff;
    border-radius: 50%;
    box-sizing: border-box; }

.midashi03 {
  position: relative;
  margin: 0 0 15px;
  padding: 5px 24px;
  background: #fdfcfc;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  color: #ff7996;
  font-size: 1.8rem;
  line-height: 1.6; }
  .midashi03::before {
    position: absolute;
    top: 50%;
    left: -1px;
    display: block;
    content: "";
    width: 10px;
    height: 4px;
    margin: -2px 0 0;
    background: #ff7996;
    border-radius: 0 2px 2px 0; }

.midashi04 {
  margin: 0 0 15px;
  padding: 0 10px 5px;
  border-bottom: 1px dotted #ff8da5;
  color: #fc6686;
  font-size: 1.7rem;
  line-height: 1.6; }

.midashi05 {
  color: #2fb1e8;
  font-size: 1.5rem;
  font-weight: bold; }

/*----- .block -----*/
.block {
  padding: 20px 24px 40px;
  background: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 5px; }
  .block:not(:last-child) {
    margin: 0 0 25px; }
  .block p:not(:last-child),
  .block ol:not(:last-child),
  .block ul:not(:last-child) {
    margin: 0 0 20px; }

.sub_block:not(:last-child) {
  margin: 0 0 40px; }

/*----- .dotted_box -----*/
.dotted_box {
  border: 1px dotted #ff94ab;
  border-radius: 5px;
  padding: 15px 20px; }

/*----- table -----*/
table {
  width: 100%; }
  table:not(:last-child) {
    margin: 0 0 20px; }
  table th,
  table td {
    padding: 15px;
    border-left: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
    line-height: 1.6; }
  table th:first-child,
  table td:first-of-type {
    border-left: none; }
  table thead th,
  table th.th01 {
    background: #ff88a2;
    color: #fff; }
  table tbody th {
    background: #f8f8f8; }
  table .list_disc li::before {
    top: 8px; }

/*----- .list_disc -----*/
.list_disc li {
  position: relative;
  padding: 0 0 0 1em; }
  .list_disc li::before {
    position: absolute;
    top: 10px;
    left: 3px;
    display: block;
    content: "";
    width: 4px;
    height: 4px;
    background: #fc6686;
    border-radius: 50%; }

/*----- .column01 / .column02 / .column03 -----*/
.column01,
.column02,
.column03 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  text-align: center; }
  .column01 figure img,
  .column02 figure img,
  .column03 figure img {
    width: 100%; }
  .column01 figcaption,
  .column02 figcaption,
  .column03 figcaption {
    margin: 10px 0 0;
    font-size: 1.2rem;
    line-height: 1.6; }
  .column01 .border,
  .column02 .border,
  .column03 .border {
    display: inline-block;
    position: relative; }
    .column01 .border::before,
    .column02 .border::before,
    .column03 .border::before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      border: 1px solid #d9d9d9; }

.column01 figure {
  width: 100%; }
  .column01 figure:not(:first-child) {
    margin: 20px 0 0; }

.column02 figure {
  width: 334px;
  margin: 20px 0 0 0; }
  .column02 figure:nth-child(-n+2) {
    margin-top: 0; }

.column03 figure {
  width: 220px;
  margin: 20px 0 0 0; }
  .column03 figure:nth-child(-n+3) {
    margin-top: 0; }

footer {
  margin: 50px 0 0; }
  footer #foot_navi {
    background: #fff;
    border: 1px solid #e7e7e7;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 20px 30px; }
    footer #foot_navi ul {
      display: flex;
      flex-wrap: wrap; }
      footer #foot_navi ul li {
        margin: 0 25px 0 0; }
        footer #foot_navi ul li a:hover {
          color: #ff7996; }
        footer #foot_navi ul li a i {
          margin: 0 8px 0 0;
          color: #ff7996; }
  footer small {
    display: block;
    text-align: center;
    color: #979797;
    font-size: 1.0rem;
    padding: 30px 0 40px; }

.pagetop {
  display: none;
  position: fixed;
  right: 30px;
  bottom: 30px;
  background: #f90; }
  .pagetop.show {
    display: block; }
  .pagetop a {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 60px;
    height: 60px;
    padding: 32px 0 0;
    background: #ff7996;
    box-sizing: border-box;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    overflow: hidden; }
    .pagetop a i {
      position: absolute;
      top: -5px;
      left: 0;
      right: 0;
      font-size: 4.4rem; }
    .pagetop a:hover {
      opacity: .7; }

#index header {
  top: 0 !important;
  position: relative !important; }

#index #visual {
  position: relative;
  top: 50px;
  width: 747px;
  margin: 0 0 0 253px; }
  #index #visual #slide-wrap {
    border-radius: 10px 0;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.05);
    overflow: hidden; }
    #index #visual #slide-wrap::before, #index #visual #slide-wrap::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      border-radius: 10px 0; }
    #index #visual #slide-wrap::before {
      border: 3px solid #fff;
      z-index: 2; }
    #index #visual #slide-wrap::after {
      border: 1px solid #e7e7e7;
      z-index: 3; }
    #index #visual #slide-wrap > .bxslider > li:not(:first-child) {
      display: none; }
  #index #visual .bx-wrapper {
    margin: 0 auto; }
    #index #visual .bx-wrapper .bx-viewport {
      border-radius: 10px 0;
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.05);
      box-shadow: none;
      border: none;
      left: 0; }
    #index #visual .bx-wrapper .bx-pager {
      position: absolute;
      right: 20px;
      bottom: 20px;
      width: auto;
      font-size: 0; }
      #index #visual .bx-wrapper .bx-pager a {
        background: #fff;
        border: 1px solid #fe3d66;
        box-sizing: border-box; }
        #index #visual .bx-wrapper .bx-pager a.active {
          background: #fe3d66; }

#index #contents {
  width: 100%;
  min-height: auto;
  margin: 12px 0 0;
  padding: 0; }

#index #index_menu > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  #index #index_menu > ul > li {
    width: 241px;
    height: 260px;
    box-sizing: border-box;
    border-radius: 10px 0;
    background: #fff;
    border: 1px solid #e7e7e7;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.05); }
    #index #index_menu > ul > li:nth-child(n+5) {
      margin: 10px 0 0; }
    #index #index_menu > ul > li#news {
      display: flex;
      flex-direction: column;
      padding: 9px; }
      #index #index_menu > ul > li#news h2 {
        margin: 0 8px;
        color: #505050;
        font-family: 'Maru Folk Medium';
        font-size: 1.8rem; }
        #index #index_menu > ul > li#news h2 i {
          position: relative;
          top: 6px;
          margin: 0 5px 0 0;
          color: #ff7996;
          font-size: 3.0rem; }
      #index #index_menu > ul > li#news ul {
        flex-grow: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch; }
        #index #index_menu > ul > li#news ul li {
          padding: 10px 5px;
          font-size: 1.2rem;
          line-height: 1.5; }
          #index #index_menu > ul > li#news ul li:not(:last-child) {
            border-bottom: 1px dotted #dfdddd; }
          #index #index_menu > ul > li#news ul li time {
            display: block;
            color: #fc6686;
            font-weight: bold; }
      #index #index_menu > ul > li#news > a {
        font-size: 1.2rem;
        text-align: right;
        margin: 5px 0 0; }
        #index #index_menu > ul > li#news > a i {
          color: #ff7996;
          margin: 0 5px 0 0; }
    #index #index_menu > ul > li:not(#news) a {
      position: relative;
      display: block;
      width: 239px;
      height: 258px;
      box-shadow: inset 2px 2px 0 white, inset -2px -2px 0 white;
      border-radius: 10px 0; }
      #index #index_menu > ul > li:not(#news) a i {
        position: absolute;
        bottom: 115px;
        left: 0;
        right: 0;
        color: #ff7996;
        font-size: 10.0rem;
        text-align: center;
        line-height: 1; }
      #index #index_menu > ul > li:not(#news) a p {
        position: absolute;
        top: 160px;
        left: 2px;
        right: 2px;
        padding: 10px 0 15px;
        background: #ff7996;
        color: #fff;
        font-family: 'Maru Folk Regular',sans-serif;
        font-size: 2.2rem;
        text-align: center;
        vertical-align: baseline;
        line-height: 1.2; }
      @media all and (-ms-high-contrast: none) {
        #index #index_menu > ul > li:not(#news) a *::-ms-backdrop, #index #index_menu > ul > li:not(#news) a p {
          padding: 15px 0 5px; } }
    #index #index_menu > ul > li#about a {
      background: url(/common/img/index/bg_about.png) center center no-repeat; }
      #index #index_menu > ul > li#about a:hover {
        background: url(/common/img/index/bg_about_on.png) center center no-repeat; }
    #index #index_menu > ul > li#facility a {
      background: url(/common/img/index/bg_place.png) center center no-repeat; }
      #index #index_menu > ul > li#facility a:hover {
        background: url(/common/img/index/bg_place_on.png) center center no-repeat; }
    #index #index_menu > ul > li#education a {
      background: url(/common/img/index/bg_education.png) center center no-repeat; }
      #index #index_menu > ul > li#education a:hover {
        background: url(/common/img/index/bg_education_on.png) center center no-repeat; }
    #index #index_menu > ul > li#voice a {
      background: url(/common/img/index/bg_voice.png) center center no-repeat; }
      #index #index_menu > ul > li#voice a:hover {
        background: url(/common/img/index/bg_voice_on.png) center center no-repeat; }
    #index #index_menu > ul > li#inquiry a {
      background: url(/common/img/index/bg_inquiry.png) center center no-repeat; }
      #index #index_menu > ul > li#inquiry a:hover {
        background: url(/common/img/index/bg_inquiry_on.png) center center no-repeat; }
    #index #index_menu > ul > li#recruit a {
      background: url(/common/img/index/bg_recruit.png) center center no-repeat; }
      #index #index_menu > ul > li#recruit a:hover {
        background: url(/common/img/index/bg_recruit_on.png) center center no-repeat; }
      #index #index_menu > ul > li#recruit a p {
        top: 145px;
        line-height: 1.4;
        font-size: 2.0rem; }
    #index #index_menu > ul > li#welfare a {
      background: url(/common/img/index/bg_welfare.png) center center no-repeat; }
      #index #index_menu > ul > li#welfare a:hover {
        background: url(/common/img/index/bg_welfare_on.png) center center no-repeat; }

html.mobile #index #index_menu > ul > li#about a,
html.tablet #index #index_menu > ul > li#about a {
  background: url(/common/img/index/bg_about_on.png) center center no-repeat; }

html.mobile #index #index_menu > ul > li#facility a,
html.tablet #index #index_menu > ul > li#facility a {
  background: url(/common/img/index/bg_place_on.png) center center no-repeat; }

html.mobile #index #index_menu > ul > li#education a,
html.tablet #index #index_menu > ul > li#education a {
  background: url(/common/img/index/bg_education_on.png) center center no-repeat; }

html.mobile #index #index_menu > ul > li#voice a,
html.tablet #index #index_menu > ul > li#voice a {
  background: url(/common/img/index/bg_voice_on.png) center center no-repeat; }

html.mobile #index #index_menu > ul > li#inquiry a,
html.tablet #index #index_menu > ul > li#inquiry a {
  background: url(/common/img/index/bg_inquiry_on.png) center center no-repeat; }

html.mobile #index #index_menu > ul > li#recruit a,
html.tablet #index #index_menu > ul > li#recruit a {
  background: url(/common/img/index/bg_recruit_on.png) center center no-repeat; }

html.mobile #index #index_menu > ul > li#welfare a,
html.tablet #index #index_menu > ul > li#welfare a {
  background: url(/common/img/index/bg_welfare_on.png) center center no-repeat; }

/* ============================================================ */
/* 看護部紹介 */
/* #about_index */
/* ============================================================ */
#about_index .block table th {
  width: 150px; }

#about_index .block table td {
  text-align: left; }

#about_index #greeting {
  overflow: hidden; }
  #about_index #greeting figure {
    float: right;
    margin: 0 0 0 40px; }
    #about_index #greeting figure figcaption {
      font-size: 1.3rem;
      margin: 10px 0 0; }

#about_index .policy .dotted_box p {
  color: #fc6686;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.6;
  text-align: center; }

#about_index #graph div {
  display: flex;
  flex-wrap: wrap; }
  #about_index #graph div figure {
    width: 100%;
    padding: 30px 0;
    box-sizing: border-box;
    text-align: center; }
    #about_index #graph div figure figcaption {
      display: block;
      height: 30px;
      margin: 0 auto 20px;
      background: #ff7996;
      border-radius: 15px;
      color: #fff;
      font-size: 1.7rem;
      line-height: 30px; }
    #about_index #graph div figure img {
      display: block;
      margin: 0 auto; }
      #about_index #graph div figure img:not(:last-child) {
        margin: 0 0 5px; }
    #about_index #graph div figure#graph01 {
      padding: 15px 20px 0 0; }
      #about_index #graph div figure#graph01 figcaption {
        width: 180px; }
    #about_index #graph div figure#graph02 {
      margin: 30px 0 0;
      border-top: 1px dotted #dfdddd;
      border-bottom: 1px dotted #dfdddd; }
      #about_index #graph div figure#graph02 figcaption {
        width: 200px; }
    #about_index #graph div figure#graph03 {
      padding-bottom: 0; }
      #about_index #graph div figure#graph03 figcaption {
        width: 270px; }

#about_index #graph p {
  text-align: right;
  margin: 15px 0 0; }

/* ============================================================ */
/* 施設・病棟紹介 */
/* #facility_index */
/* ============================================================ */
#facility_index .column03 {
  margin: 0 0 15px; }

#facility_index table td {
  text-align: left; }

#facility_index .pic_hospital {
  width: 270px;
  margin: 0 20px 0 0;
  float: left; }

#facility_index .map {
  width: 397px;
  height: 230px;
  margin: 0 0 20px; }

/* ============================================================ */
/* 教育・研修 */
/* #education_index */
/* ============================================================ */
#education_index table th,
#education_index table td {
  padding: 12px 15px;
  vertical-align: top; }

#education_index table td {
  text-align: left; }

#education_index .column02 figure {
  width: 335px; }

#education_index #program table th,
#education_index #program table td {
  font-size: 1.3rem; }

#education_index #capabilities #certified_nurse img {
  float: left;
  margin: 0 25px 0 0; }

#education_index #capabilities #certified_nurse .midashi05 {
  margin: 0 0 10px; }

/* ============================================================ */
/* 先輩の声 */
/* #voice_index */
/* ============================================================ */
#voice_index #contents > p {
  text-align: right; }

#voice_index .tab {
  display: flex;
  border: 1px solid #ffdce3;
  border-radius: 3px;
  margin: 0 0 30px;
  overflow: hidden; }
  #voice_index .tab li {
    width: 138px;
    background: #fff8f8;
    text-align: center;
    padding: 15px 0;
    border-left: 1px solid #ffdce3;
    position: relative; }
    #voice_index .tab li:first-child {
      border: none; }
    #voice_index .tab li::after {
      font-family: 'icomoon' !important;
      content: "\f107";
      position: absolute;
      left: 48%;
      bottom: 5px;
      line-height: 1rem; }
    #voice_index .tab li:hover, #voice_index .tab li.select {
      color: #fff;
      background: #ff7996;
      cursor: pointer; }

#voice_index #voice_block .sub_block {
  margin-bottom: 0; }
  #voice_index #voice_block .sub_block .voice_profile {
    display: flex;
    margin: 20px 0; }
    #voice_index #voice_block .sub_block .voice_profile dl {
      width: 50%;
      display: flex;
      flex-flow: wrap; }
      #voice_index #voice_block .sub_block .voice_profile dl dt {
        width: 95px;
        color: #fd5075;
        font-weight: bold;
        border-bottom: 1px dotted #d2d2d2;
        padding: 8px 0 8px 10px; }
      #voice_index #voice_block .sub_block .voice_profile dl dd {
        width: calc(100% - 95px);
        border-bottom: 1px dotted #d2d2d2;
        padding: 8px 0; }
  #voice_index #voice_block .sub_block .accordion dt {
    color: #fc6686;
    font-size: 1.5rem;
    padding: 10px;
    background: #fff8f8;
    border: 1px solid #ffdce3;
    border-radius: 3px;
    margin: 15px 0 0;
    position: relative; }
    #voice_index #voice_block .sub_block .accordion dt:hover {
      cursor: pointer; }
    #voice_index #voice_block .sub_block .accordion dt::after {
      font-family: 'icomoon' !important;
      content: "\f107";
      position: absolute;
      right: 15px; }
    #voice_index #voice_block .sub_block .accordion dt.opened::after {
      font-family: 'icomoon' !important;
      content: "\f106"; }
  #voice_index #voice_block .sub_block .accordion dd {
    display: none;
    border-left: 3px solid #ffa3b5;
    margin: 10px 0 25px;
    padding: 10px 10px 10px 20px; }
    #voice_index #voice_block .sub_block .accordion dd:last-child {
      margin-bottom: 0; }
  #voice_index #voice_block .sub_block .message {
    background: #fefdfd;
    border: 1px solid #eeecec;
    border-radius: 3px;
    margin: 20px 0 0;
    padding: 20px; }
    #voice_index #voice_block .sub_block .message dl dt {
      color: #fc6686;
      font-size: 1.7rem;
      margin: 0 0 5px; }
      #voice_index #voice_block .sub_block .message dl dt i {
        font-size: 3.5rem;
        vertical-align: middle;
        position: relative;
        top: -2px;
        margin: 0 5px 0 0; }
    #voice_index #voice_block .sub_block .message dl dd {
      display: flex;
      align-items: flex-start;
      justify-content: space-between; }
      #voice_index #voice_block .sub_block .message dl dd p {
        width: 430px;
        margin: 0; }
      #voice_index #voice_block .sub_block .message dl dd img {
        width: 200px;
        flex-shrink: 0; }

#voice_index #voice_block .hide {
  display: none; }

#voice_index #flow_block {
  margin: 50px 0 0; }
  #voice_index #flow_block #flow_profile {
    background: #fc6686;
    display: flex;
    align-items: center; }
    #voice_index #flow_block #flow_profile img {
      width: 300px; }
    #voice_index #flow_block #flow_profile h3 {
      color: #fff;
      margin: 0 0 0 30px;
      font-size: 2.8rem;
      font-weight: bold; }
  #voice_index #flow_block #flow {
    margin: 30px 0 0; }
    #voice_index #flow_block #flow li {
      display: flex; }
      #voice_index #flow_block #flow li:nth-child(odd) {
        background: #fff9fa; }
      #voice_index #flow_block #flow li span {
        padding: 10px 0; }
      #voice_index #flow_block #flow li span:first-child {
        width: 80px;
        color: #fd5075;
        text-align: center;
        font-weight: bold;
        border-right: 3px solid #fedfe6; }
      #voice_index #flow_block #flow li span:nth-child(2) {
        padding: 10px 15px;
        position: relative; }
        #voice_index #flow_block #flow li span:nth-child(2):before {
          content: "";
          width: 7px;
          height: 7px;
          display: block;
          border-radius: 3.5px;
          background: #fd5075;
          position: absolute;
          top: 18px;
          left: 0;
          margin: 0 0 0 -5px; }
        #voice_index #flow_block #flow li span:nth-child(2) img {
          display: block; }

/* ============================================================ */
/* 勤務・福利厚生 */
/* #welfare_inde */
/* ============================================================ */
#welfare_index #nurse table th,
#welfare_index #care_workers table th {
  width: 170px; }

#welfare_index #nurse table td,
#welfare_index #care_workers table td {
  text-align: left; }
  #welfare_index #nurse table td p,
  #welfare_index #care_workers table td p {
    margin: 0; }
  #welfare_index #nurse table td ul + p,
  #welfare_index #care_workers table td ul + p {
    margin-top: 5px; }

#welfare_index .dotted_box ul li a[href$=".pdf"] {
  position: relative; }
  #welfare_index .dotted_box ul li a[href$=".pdf"]:hover {
    color: #ff7996; }
  #welfare_index .dotted_box ul li a[href$=".pdf"]::after {
    content: "\e905";
    font-family: 'icomoon' !important;
    font-size: 2.5rem;
    position: absolute;
    margin: -11px 0 0; }

#welfare_index #mental_care .dotted_box {
  margin: 0 0 20px; }

#welfare_index #mental_care table td {
  text-align: left; }
  #welfare_index #mental_care table td p {
    margin: 0 0 5px; }

/* ============================================================ */
/* お問い合わせ 共通スタイル */
/* .inquiry */
/* ============================================================ */
.inquiry .must {
  color: #fc6686; }

.inquiry table {
  margin: 0 0 30px; }
  .inquiry table th {
    width: 200px; }
  .inquiry table td {
    text-align: left; }

.inquiry .btn_block {
  display: flex;
  justify-content: center; }
  .inquiry .btn_block .btn {
    width: 150px;
    height: 50px;
    margin: 0 10px;
    background: #ff7996;
    border-radius: 5px;
    color: #fff;
    font-size: 1.6rem;
    cursor: pointer; }
    .inquiry .btn_block .btn i {
      margin: 0 10px 0 0;
      font-size: 1.2rem; }
    .inquiry .btn_block .btn:hover {
      opacity: .7; }

/* ============================================================ */
/* お問い合わせ 入力画面 */
/* #inquiry_index */
/* ============================================================ */
#inquiry_index input[type="text"] {
  width: 420px;
  padding: 5px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 3px; }

#inquiry_index input[name="zip"] {
  width: 170px;
  margin: 0 0 0 5px; }

#inquiry_index input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset; }

#inquiry_index textarea {
  width: 420px;
  height: 150px;
  padding: 5px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 3px; }

#inquiry_index .radio {
  margin: 0 30px 0 0; }

#inquiry_index .alert {
  margin: 30px 0 25px;
  padding: 15px 20px;
  background: #fbe2e1;
  border: 1px solid #f9d1d1;
  color: #fc6686;
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center; }

#inquiry_index .error {
  margin: 10px 0 -5px;
  color: #ff3737;
  font-weight: bold; }

/* ============================================================ */
/* お問い合わせ 完了画面 */
/* #inquiry_complete */
/* ============================================================ */
#inquiry_complete .block strong {
  position: relative;
  top: 1px;
  color: #39a8cc;
  font-size: 1.8rem;
  font-weight: bold; }

/* Scss Document */
/* ============================================================ */
/* 採用情報 */
/* #recruit_index */
/* ============================================================ */
#recruit_index #inturn .dotted_box {
  margin: 0 0 15px; }

#recruit_index .dotted_box ul li a[href$=".pdf"] {
  position: relative; }
  #recruit_index .dotted_box ul li a[href$=".pdf"]:hover {
    color: #ff7996; }
  #recruit_index .dotted_box ul li a[href$=".pdf"]::after {
    content: "\e905";
    font-family: 'icomoon' !important;
    font-size: 2.5rem;
    position: absolute;
    margin: -11px 0 0; }

#recruit_index .btn_block a {
  width: 350px;
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  display: block;
  background: #ff7996;
  margin: 15px auto 0;
  padding: 10px 0;
  border-radius: 5px; }
  #recruit_index .btn_block a i {
    margin: 0 10px 0 0; }
  #recruit_index .btn_block a:hover {
    background: #fd567a; }

#recruit_index #scholarship table th {
  width: 110px; }

#recruit_index #scholarship table td {
  text-align: left; }

/* ============================================================ */
/* プライバシーポリシー */
/* #privacy_index */
/* ============================================================ */
#privacy_index .midashi01 i {
  margin: 15px 0;
  font-size: 7.0rem; }

#privacy_index .list_disc li:not(:last-child) {
  margin: 0 0 10px; }

/* ============================================================ */
/* サイトマップ */
/* #sitemap_index */
/* ============================================================ */
#sitemap_index .midashi01 i {
  margin: 15px 0;
  font-size: 6.0rem; }

#sitemap_index .block ul li {
  padding: 10px;
  border-bottom: 1px dotted #dfdddd;
  font-size: 1.8rem; }
  #sitemap_index .block ul li i {
    margin: 0 10px 0 0;
    color: #ff7996;
    font-size: 1.5rem; }

/* ============================================================ */
/* 看護部NEWS */
/* #news_index */
/* ============================================================ */
#news_index #news_list li {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px 5px;
  border-bottom: 1px dotted #dfdddd; }
  #news_index #news_list li:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  #news_index #news_list li > *:not(time) {
    flex: 1; }
  #news_index #news_list li time {
    width: 100px;
    color: #fc6686; }
  #news_index #news_list li a:hover {
    text-decoration: underline; }

#news_index .pager ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  #news_index .pager ul li {
    margin: 0 2px 5px; }
    #news_index .pager ul li .page_numbers {
      display: block;
      width: 40px;
      height: 40px;
      background: #fff;
      box-sizing: border-box;
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      text-align: center;
      line-height: 40px; }
      #news_index .pager ul li .page_numbers.current, #news_index .pager ul li .page_numbers:hover {
        background: #ff7996;
        border: 1px solid #ff7996;
        color: #fff; }

/* ============================================================ */
/* 看護部NEWS 詳細 */
/* #news_detail */
/* ============================================================ */
#news_detail .entry_title {
  margin: 0 0 30px;
  padding: 0 0 15px;
  border-bottom: 1px dotted #dfdddd; }
  #news_detail .entry_title h2 {
    margin: 0 0 10px;
    color: #fc6686;
    font-size: 2.4rem;
    line-height: 1.5; }

#news_detail .entry_pager {
  position: relative; }
  #news_detail .entry_pager .prev {
    position: absolute;
    top: 0;
    left: 0; }
  #news_detail .entry_pager .next {
    position: absolute;
    top: 0;
    right: 0; }
  #news_detail .entry_pager .list {
    text-align: center; }
  #news_detail .entry_pager a {
    display: inline-block;
    padding: 0 12px;
    background: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    line-height: 40px; }
    #news_detail .entry_pager a:hover {
      color: #fff;
      border: 1px solid #ff7996;
      background: #ff7996; }
